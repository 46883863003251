<template>
  <img class="profile" alt="Profile picture of Wren Rudolph" src="@/assets/images/profiles/headshot-wren-rudolph.jpg" />
</template>

<script>
export default {
  name: "WrenProfile"
}
</script>

<style scoped>
.profile {
  border-radius: 5px 5px 0 0;
}
</style>
